import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import TitleUnderlined from '../TitleUnderlined';

export const SectionContainer = styled.div`
  ${props => {
    if (props.containerCss) {
      return props.containerCss;
    } else {
      return `
        display: flex;
        flex-direction: column;
        align-items: flex-end; 
      `;
    }
  }}
  @media (max-width: 1219px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const SectionCard = styled.div`
  ${props => {
    if (props.cardCss) {
      return props.cardCss;
    } else {
      return `
        max-width: 545px;
        margin-bottom: 120px;  
      `;
    }
  }}

  background-color:#212121!important;

  &:nth-of-type(1) {
    margin-left: 0;
  }

  &:nth-of-type(3) {
    margin-left: 0;
  }

  @media (max-width: 1219px) {
    max-width: 470px;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 965px) {
    ${props => {
      if (props.tabletCardCss) {
        return props.tabletCardCss;
      }
    }}
  }
  @media (max-width: 735px) {
    ${props => {
      if (props.mobileCardCss) {
        return props.mobileCardCss;
      }
    }}
  }
  @media (max-width: 500px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  @media screen and (min-width: 320px) and (max-width: 800px) {
    ${props => {
      if (props.mobileCardCss) {
        return props.mobileCardCss;
      }
    }}
  }
`;

const SectionContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SectionContent = styled.div`
  ${props => {
    if (props.contentCss) {
      return props.contentCss;
    } else {
      return `
        font-family: 'DM Sans', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 180%;
        letter-spacing: 0.01em;
        color: #404040;
        margin-top: 6px;
        text-align: left;
    `;
    }
  }}
  text-align: left;
  @media (max-width: 965px) {
    font-size: 15px;
  }
  @media (max-width: 735px) {
    font-size: 14px;
    border-bottom: 1px dashed #e4e4e4;
    border-bottom: 0px dashed #e4e4e4;
    padding-bottom: 25px;
  }
`;

const titleCss = {
  fontSize: '32px',
  tabletFontSize: '26px',
  tableLineHeight: '28px',
  mobileFontSize: '18px',
  mobileLineHeight: '22px',
  lineHeight: '40px',
};

const CardSection = props => {
  const {
    data,
    contentCss,
    cardCss,
    containerCss,
    inlineIndex,
    titleUnderlined1Css,
    titleUnderlined2Css,
    mobileCardCss,
    tabletCardCss,
    displayHappen,
  } = props;
  const titleUnder1Css = titleUnderlined1Css || titleCss;
  const titleUnder2Css = titleUnderlined2Css || titleCss;
  return (
    <SectionContainerOuter>
      <SectionContainer containerCss={containerCss} id="cardContainer">
        {data.map((item, index) => (
          <SectionCard
            id={`contentSection_${index}`}
            key={`section_${Math.random()}`}
            cardCss={cardCss}
            mobileCardCss={mobileCardCss}
            tabletCardCss={tabletCardCss}
          >
            {inlineIndex ? (
              <TitleUnderlined
                titleText={
                  displayHappen
                    ? `<span> ${index + 1}. ${item.card_title.text || ''}`
                    : `<span> ${index + 1}. ${item.title} </span>`
                }
                fontSize="32px"
                tabletFontSize="26px"
                tableLineHeight="28px"
                mobileFontSize="18px"
                mobileLineHeight="22px"
                lineHeight="40px"
              />
            ) : (
              <Fragment>
                <TitleUnderlined
                  titleText={`<span> ${index + 1}. </span>`}
                  {...titleUnder1Css}
                />
                <TitleUnderlined
                  titleText={
                    displayHappen
                      ? `<span> ${item.card_title.text || ''} </span>`
                      : `<span> ${item.title} </span>`
                  }
                  {...titleUnder2Css}
                  leftAligned="left"
                />
              </Fragment>
            )}
            <SectionContent contentCss={contentCss}>
              {displayHappen ? item.card_text.text || '' : item.content}
            </SectionContent>
          </SectionCard>
        ))}
      </SectionContainer>
    </SectionContainerOuter>
  );
};

export default CardSection;
